import React from 'react';
import { Container } from 'react-bootstrap';
import "./Footer.css";

function Footer() {
    
  return (
    <footer>
        <Container>
            <div className='footer-head'>
                <img src='https://s3.amazonaws.com/accelerator.app/profile_picture_UGWhR2ttw' alt='logo' />
            </div>
            <div className='footer-bottom'>
                <p>Copyright 2022 Hub71.</p>
            </div>
        </Container>
    </footer>
  )
}

export default Footer;