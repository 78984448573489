import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaArrowRight } from "react-icons/fa";
import LogoBlack from "../../assets/Images/logo.png";
import LogoWhite from "../../assets/Images/logo-white.png";
import "./Navigation.css"

function Navigation() {

  const [navbarBackgroundColor, setNavbarBackgroundColor] = useState("");

  useEffect(() => {
    if(typeof window !== "undefined"){
      const handleScroll = () => {
        if (window.scrollY > 350) {
          setNavbarBackgroundColor("navbar-background-color-active");
        }else {
          setNavbarBackgroundColor("");
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [])

  return (
    <header className={`header ${navbarBackgroundColor}`}>
      <Navbar expand='sm'>
        <Container>
          <Navbar.Brand href="#">
            <img alt='logo' src={navbarBackgroundColor ? LogoBlack : LogoWhite} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                <img width='75px' height='35px' alt='logo' src='https://s3.amazonaws.com/accelerator.app/profile_picture_UGWhR2ttw' />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="nav justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="#AlumniMember">Partners</Nav.Link>
                <Nav.Link href="#Events">Events</Nav.Link>
                <Nav.Link href="#Services">Services</Nav.Link>
                <Nav.Link href="#Perks">Perks</Nav.Link>
              </Nav>
              <div className='signin-btn'>
                <Button variant="primary">
                  <span>Sign in</span>
                  <span><FaArrowRight /></span>
                </Button>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  )
}

export default Navigation;