import React from 'react';
import Member1 from "../../assets/Images/Members/_EDX8447.jpg";
import Member2 from "../../assets/Images/Members/_EDX8462.jpg";
import Member3 from "../../assets/Images/Members/_EDX8516.jpg";
import Member4 from "../../assets/Images/Members/_EDX8523.jpg";
import "./AlumniMembers.css";

function AlumniMembers() {

    const membersData = [
        {
            name: "Darrell Steward",
            title: "Co-founder",
            img: Member1,
            company: "Amwal"
        },
        {
            name: "John Nguyen",
            title: "Co-founder",
            img: Member2,
            company: "Dapio"
        },
        {
            name: "Bertrand Russell",
            title: "Co-founder",
            img: Member3,
            company: "Bayzat"
        },
        {
            name: "James Watson",
            title: "Co-founder",
            img: Member4,
            company: "Denarii Cash"
        }
    ]
  return (
    <div className='alumni-members'>
        <div className='container'>
            <div>
                <div className='section-heading'>
                    <h6>Meet the People Behind the Vision</h6>
                    <p>Our founders are a group of experienced entrepreneurs who are passionate about building a successful company.</p>
                </div>
                <div className='card-section row'>
                    {membersData.map((item: any, index: any) => (
                        <div className='col-lg-3 col-md-6 col-12' key={index}>
                            <div className='profile-card'>
                                <img src={item.img} alt='member'/>
                                <h6>{item.name}</h6>
                                <p>{item.title}</p>
                                <span>{item.company}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default AlumniMembers;