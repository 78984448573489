import React from 'react';
import Hero from '../components/views/Hero';
import AlumniMembers from '../components/views/AlumniMembers';
import Services from '../components/views/Services';
import Events from '../components/views/Events';
import Perks from '../components/views/Perks';
// import HeroSections from '../components/views/HeroSections';

function Home() {
  return (
    <div>
        <Hero />
        <AlumniMembers />
        <Services />
        <Events />
        <Perks />
    </div>
  )
}

export default Home;