import React from 'react';
import { FiActivity } from "react-icons/fi";
import { GoCalendar, GoCode } from "react-icons/go";
import { RiCarLine } from "react-icons/ri";
import "./Services.css";

function Services() {
  return (
    <div className='services-section'>
        <div className='container'>
            <div>
                <div className='section-heading'>
                    <h6>SERVICES</h6>
                    <p>Strong network of service providers offering discounted pricing for Hub71 Alumni. Services across multiple categories.</p>
                </div>
                <div className='services-card-section row'>
                    <div className='col-lg-6 col-12' >
                        <div className='service-card'>
                            <GoCalendar />
                            <p>Nibh nullam vitae semper pharetra sit enim id. Ut eu non massa nec. Proin eget semper orci suspendisse in ornare adipiscing phasellus mauris. Velit faucibus at habitasse tempor sit odio ac commodo dui. </p>
                        </div>
                    </div>
                    <div className='col-lg-6 col-12' >
                        <div className='service-card'>
                            <GoCode/>
                            <p>Sed et pulvinar donec sed et, nisl dolor amet. Mollis aliquet volutpat ullamcorper ac sed lectus iaculis. Fringilla sed placerat commodo bibendum integer. Diam ut magna eleifend consectetur. </p>
                        </div>
                    </div>
                    <div className='col-lg-6 col-12' >
                        <div className='service-card'>
                            <FiActivity />
                            <p>Nunc amet, tempor morbi ligula ut faucibus gravida. Accumsan, suspendisse mus quisque pellentesque id vulputate hendrerit. Donec ipsum nibh elementum platea proin egestas gravida consectetur sit.</p>
                         </div>
                    </div>
                    <div className='col-lg-6 col-12' >
                        <div className='service-card'>
                            <RiCarLine />
                            <p>Pellentesque auctor adipiscing lacus viverra. Neque, nulla in amet eget. Arcu, nibh purus urna amet sagittis quis tellus etiam eget. Ultrices egestas a tristique aliquet odio varius. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Services;