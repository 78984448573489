import React from 'react';
import Card from 'react-bootstrap/Card';
import "./Perks.css";

function Perks() {
    const cardsData = [
        { 
            title: "GITHUB, INC", 
            subTitle: "CODE HOSTING",
            subCat: "CATEGORIES",
            text1: "Development, Hosting, Productivity, Security",
            text2: "CMS, Cloud Hosting, Integration",
            subCat2: "TAGS",
            value: "VALUE $7,500",
            image: "https://play-lh.googleusercontent.com/PCpXdqvUWfCW1mXhH1Y_98yBpgsWxuTSTofy3NGMo9yBTATDyzVkqU580bfSln50bFU" 
        },
        { 
            title: "HUBSPOT", 
            subTitle: "CRM PLATFORM",
            subCat: "CATEGORIES",
            text1: "Available Worldwide, Marketing, Sales",
            text2: "CMS, CRM, Communication, Email",
            subCat2: "TAGS",
            value: "VALUE $17,000",
            image: "https://pbs.twimg.com/profile_images/1500923494665797632/VytKgxOP_400x400.jpg" 
        },
        { 
            title: "SLACK", 
            subTitle: "25% OFF UPGRADES TO SLACK PRO...",
            subCat: "CATEGORIES",
            text1: "Available Worldwide, Productivity",
            text2: "Collaboration, Communication, Email",
            subCat2: "TAGS",
            value: "VALUE $9,000",
            image: "https://a.slack-edge.com/80588/marketing/img/meta/slack_hash_256.png" 
        },
        { 
            title: "AMERICAN EXPRESS", 
            subTitle: "CREDIT CARD SERVICES",
            subCat: "CATEGORIES",
            text1: "Available Worldwide, Financial",
            text2: "Credit Card",
            subCat2: "TAGS",
            value: "VALUE $1,000",
            image: "https://upload.wikimedia.org/wikipedia/commons/f/fa/American_Express_logo_%282018%29.svg" 
        }
    ]

    return (
        <div className='perks'>
            <div className='container'>
                <div>
                    <div className='section-heading'>
                        <h6>PERKS</h6>
                        <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum amet at molestie mattis.</h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem, nemo rerum maiores delectus quae commodi quod recusandae eos cupiditate, ipsa a nam voluptates qui. Dolor architecto voluptates quas corrupti rem.</p>
                    </div>
                    <div className='cards-section'>
                        <div className='row'>
                            {cardsData.map((item: any, index: any) => (

                                <div className='col-lg-3 col-md-6 col-12' key={index}>
                                    <Card style={{ width: '18rem' }}>
                                        <Card.Img variant="top" src={item.image} />
                                        <Card.Body>
                                            <Card.Text className='value-text'>{item.value}</Card.Text>
                                            <Card.Subtitle className='head-title'>{item.subTitle}</Card.Subtitle>
                                            <Card.Title>{item.title}</Card.Title>
                                            <Card.Subtitle>{item.subCat}</Card.Subtitle>
                                            <Card.Text>{item.text1}</Card.Text>
                                            <Card.Subtitle>{item.subCat2}</Card.Subtitle>
                                            <Card.Text>{item.text2}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Perks;