import React from 'react';
import "./Hero.css";

function Hero() {
  return (
    <div className='hero-section'>
        <video autoPlay muted preload="auto" loop id="myVideo" playsInline className="">
            <source src="https://20332845.fs1.hubspotusercontent-na1.net/hubfs/20332845/PortalFiles/alumni_landing_video.mp4" type="video/mp4" />
        </video>
        <div className='container'>
            <div className='content'>
                <h1 className='brand-name'>Hub71 Alumni</h1>
                <h1>Connect, Grow, Thrive</h1>
                <p>A space for startups to connect with each other, share ideas, and collaborate. Access to resources and mentors, helping startups stay up-to-date on the latest trends in the startup ecosystem.</p>
            </div>
        </div>
    </div>
  )
}

export default Hero