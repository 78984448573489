import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { Card } from 'react-bootstrap'
import "./Events.css"

function Events() {
    const reduceRecipes = (acc: any, cur: any, index: any) => {
        let divider;

        if(typeof window !== 'undefined' && window.innerWidth < 541){
            divider = 1
        }else if(typeof window !== 'undefined' && window.innerWidth < 913){
            divider = 2
        }else{
            divider = 3
        }

        const groupIndex = Math.floor(index / divider);
        if (!acc[groupIndex]) acc[groupIndex] = [];
        acc[groupIndex].push(cur);
        return acc;
    };
    const photoLocations = [
    {
        title: "Abu Dhabi Tech Link: Igniting Innovation Across Borders", 
        text: "29-31 Rue de Courcelles 75008  Paris, France • Paris", 
        time: "Wed, Jun 14, 7:00 PM", 
        photo_location: "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F527709749%2F540601580725%2F1%2Foriginal.20230602-092528?h=230&w=460&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C455%2C5218%2C2609&s=7f763993cafa884430232bc3d8a1fcf7"
    },
    {
        title: "HUB71+ Digital Assets: Unleashing Innovation, Empowering Success", 
        text: "Hub71 • Abu Dhabi, Abu Dhabi", 
        time: "Mon, Jun 5, 3:30 PM", 
        photo_location: "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F523199479%2F540601580725%2F1%2Foriginal.20230526-095719?h=230&w=460&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C2160%2C1080&s=c182f3262fd7fb581762e27f7dcd29bf"
    },
    {
        title: "Getting Started with Apple Business | HUB71 - Video Session", 
        text: "Apple Store, The Galleria Mall • Abu Dhabi", 
        time: "Wed, Aug 10, 11:00 AM", 
        photo_location: "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F329018739%2F540601580725%2F1%2Foriginal.20220803-114601?h=230&w=460&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C40%2C1280%2C640&s=de2de5ff00a8c0705f5ac4b24b76d2d3"
    },
    {
        title: "Crypto Capital: Crystalizing the Future of a Diversified Digital Assets Hub", 
        text: "Hub71 • Abu Dhabi, Abu Dhabi", 
        time: "Fri, Nov 4, 2:00 PM", 
        photo_location: "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F380574549%2F540601580725%2F1%2Foriginal.20221026-051819?h=230&w=460&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C1800%2C900&s=4e942f1392f1ce6b739cd66c305513ff"
    },
    {
        title: "How to build a Life-Science Ecosystem", 
        text: "Hub71 • Abu Dhabi, Abu Dhabi", 
        time: "Tue, Feb 21, 11:00 AM", 
        photo_location: "https://cdn.evbstatic.com/s3-build/prod/1289128-rc2023-06-15_16.04-d4fb13b/django/images/discovery/default_logos/5.png"
    },
    {
        title: "FIRESIDE CHAT: Finding Your Why", 
        text: "Hub71 • Abu Dhabi, Abu Dhabi", 
        time: "Wed, Feb 1, 3:00 PM", 
        photo_location: "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F433380489%2F540601580725%2F1%2Foriginal.20230127-085056?h=230&w=460&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C249%2C1300%2C650&s=d085adc0a1a40f73112fbdc9d2f39b38"
    }
    ]

    return (
        <div className='events-section'>
            <div className='container'>
                <div>
                    <div className='section-heading'>
                        <h6>EVENTS</h6>
                        <h2>Interdum sollicitudin tortor viverra porta consequat in.</h2>
                        <p>Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum sit nunc in eros scelerisque sed. Commodo in viverra nunc, ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis, pulvinar. Fermentum scelerisque sit consectetur hac mi. Mollis leo eleifend ultricies purus iaculis.</p>
                    </div>
                    <div className="row">
                        <Carousel interval={3000}>
                        {photoLocations.reduce(reduceRecipes, []).map((item:any, index: any) => (
                            <Carousel.Item key={index}>
                                <div className="d-flex justify-content-center">
                                {item.map((item: any, index: any) => {
                                    return (
                                    <Card key={index} style={{ width: "18rem" }}>
                                        <Card.Img variant="top" src={item.photo_location} />
                                        <Card.Body>
                                        <Card.Title>{item.title}</Card.Title>
                                        <Card.Subtitle>{item.time}</Card.Subtitle>
                                        <Card.Text>{item.text}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                    );
                                })}
                                </div>
                            </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Events